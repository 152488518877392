.mentions--multiLine .mentions__highlighter {
  @apply min-h-48 px-6 pb-12 pt-3 md:px-3 md:pb-16;
}
.mentions--multiLine .mentions__input {
  @apply min-h-48 rounded-md border border-solid border-gray-300 px-6 pb-12 pt-3 md:px-3 md:pb-16;
}

.mentions__suggestions {
  @apply overflow-hidden rounded-md border border-solid border-gray-300 shadow-lg;
}

.mentions__suggestions__list {
  @apply max-h-48 overflow-y-auto text-sm;
}

.mentions__suggestions__item {
  @apply border-b border-solid border-gray-300 px-4 py-2;
}

.mentions__suggestions__item--focused {
  @apply bg-indigo-100;
}

.mentions__mention {
  @apply pointer-events-none relative z-10 text-indigo-600;
}

.mentions__readonly--multiLine .mentions__readonly__input {
  @apply break-words border border-transparent text-gray-700;
}
