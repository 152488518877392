@import '@fontsource/inter/variable-full.css';

:root {
  --reach-menu-button: 1;
  --reach-accordion: 1;
  --reach-popover: 1;
  --reach-tooltip: 1;
  --reach-portal: 1;
  --reach-checkbox: 1;
  --reach-dialog: 1;
  --reach-combobox: 1;
}

.scrollbar-none {
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.reach-menu-items {
  [data-reach-menu-item][data-selected] {
    @apply bg-indigo-100;
  }
}
.from-white-1\/2 {
  --tw-gradient-from: theme('colors.white') 50%;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.to-indigo-100-1\/2 {
  --tw-gradient-to: theme('colors.indigo.100') 50%;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
