[data-reach-custom-checkbox-container] {
  outline: none !important;
  box-shadow: none !important;
  margin-right: 0;
}
[data-reach-custom-checkbox][data-state='checked'] {
  @apply bg-indigo-600;
}
[data-reach-custom-checkbox][data-focused] {
  @apply border-indigo-600;
}
[data-reach-custom-checkbox-input][aria-checked='false']:enabled {
  outline: none;
  &:focus + svg {
    @apply text-indigo-600;
  }
  &:hover + svg {
    @apply text-indigo-600;
  }
}
[data-reach-custom-checkbox-container]:hover + label {
  color: black;
}
